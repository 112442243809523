import {
  CitiesSorting,
  GetCitiesTopDocument,
  GetCitiesTopQuery,
  GetHomeCategoriesDocument,
  GetHomeCategoriesQuery,
  GetLeafletsDocument,
  GetLeafletsForIntervalDocument,
  GetLeafletsForIntervalQuery,
  GetLeafletsQuery,
  GetOffersTopByRetailerQuery,
  GetSeoInfoDocument,
  GetSeoInfoQuery,
  GetTopOffersDocument,
  GetTopRetailersDocument,
  GetTopRetailersQuery,
  Grouping,
  OfferContainersSorting,
  Offers_Grouping,
  Offers_Sorting,
  RetailerSorting,
} from "generated/graphql/types";
import { GetServerSidePropsContext, PreviewData } from "next";

import { ApolloQueryResult } from "@apollo/client";
import DateTimeUtility from "@/commons/utilities/DateTimeUtility";
import HomePage from "@/views/home-page/HomePage";
import InitServerSide from "@/commons/serverside/InitServerSide";
import { ParsedUrlQuery } from "querystring";
import { connect } from "react-redux";
import { initializeApollo } from "apollo";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import wrapper from "@/commons/redux/reducers/stores";

const Home = (props) => <HomePage {...props} />;

export const getServerSideProps = wrapper.getServerSideProps(
  (storeRedux) =>
    async (params: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>) => {
      const paramsForPage = await InitServerSide({
        ...params,
        store: storeRedux,
        appRender: "pq-fe-site/index",
      });

      const client = initializeApollo();

      const t = usePQTranslations();

      //const { user: userState, ccontext } = storeRedux.getState();
      //const user = userState ? new UserModel(userState) : null;
      //const { rStateDeviceUser } = ccontext;
      //const deviceId = DeviceIdUtility.getId(rStateDeviceUser);

      /*       const retailerFavouritesParams = {
        deviceId: deviceId,
        favouriteType: EntityFavouritesType.RETAILER,
        returnJson: true,
      };
      const retailerFavourites = await FavouritesUtility.get(
        retailerFavouritesParams,
        user,
        rStateDeviceUser,
      ); */

      const { location } = storeRedux.getState();
      const { userLocation } = location;

      const { ccontext } = storeRedux.getState();
      const { rStateIsBot } = ccontext;

      const near =
        rStateIsBot || userLocation?.default
          ? null
          : userLocation?.latitude && userLocation?.longitude
          ? `${userLocation.latitude},${userLocation.longitude}`
          : null;

      // const offerContainersQueries = new OfferContainersQueries();

      /*  const leafletsOfRetailer =
        await offerContainersQueries.leafletsForRetailers({
          //retailers: [retailer.id],
          //fragment: OfferContainerFragments.forLeafletsWithOffers(),
          lat: userLocation.latitude,
          lng: userLocation.longitude,
          returnJson: true,
        }); */

      const startDateFrom = DateTimeUtility.convertForQuery(
        DateTimeUtility.addDays(new Date(), -3),
      );
      const startDateTo = DateTimeUtility.convertForQuery(
        DateTimeUtility.addDays(new Date(), 7),
      );

      const queriesRes = await Promise.allSettled([
        client.query<GetLeafletsForIntervalQuery>({
          query: GetLeafletsForIntervalDocument,
          variables: {
            limit: 16,
            page: 1,
            startDateFrom,
            startDateTo,
            sorting: OfferContainersSorting.StartDateBoost,
            near,
            grouping: Grouping.RetailerAndName,
          },
          fetchPolicy: "network-only",
        }),
        client.query<GetLeafletsQuery>({
          query: GetLeafletsDocument,
          variables: {
            page: 1,
            limit: 12,
            near,
            sorting: OfferContainersSorting.Top,
            grouping: Grouping.RetailerAndName,
          },
        }),
        client.query<GetTopRetailersQuery>({
          query: GetTopRetailersDocument,
          variables: {
            near,
            limit: 30,
            sorting: RetailerSorting.Rank,
          },
          fetchPolicy: "network-only",
        }),
        client.query<GetCitiesTopQuery>({
          query: GetCitiesTopDocument,
          variables: {
            excludeCurrentSlug: userLocation.slug,
            limit: 12,
            near,
            sorting: CitiesSorting.Stores,
            storesGt: 33,
          },
          fetchPolicy: "network-only",
        }),
        client.query<GetOffersTopByRetailerQuery>({
          query: GetTopOffersDocument,
          variables: {
            near,
            limit: 16,
            standalone: false,
            autoleaflet: false,
            grouping: Offers_Grouping.CategoryId,
            sorting: Offers_Sorting.Discount,
          },
          fetchPolicy: "network-only",
        }),

        client.query<GetSeoInfoQuery>({
          query: GetSeoInfoDocument,
          variables: {
            near,
          },
        }),
        client.query<GetHomeCategoriesQuery>({
          query: GetHomeCategoriesDocument,
        }),
      ]);

      //data = PromiseUtility.parseFromAllSettled("getFeaturedLeaflets", data);
      /*  paramsForPage.props.retailerFavourites = retailerFavourites
        ? retailerFavourites
        : null;
 */

      const [
        leafletsForIntervalRes,
        leafletsRes,
        topRetailersRes,
        topCitiesRes,
        offersRetailerRes,
        seoInfoRes,
        // homeCategoriesRes,
      ] = queriesRes;

      const leafletsForIntervalVal = (leafletsForIntervalRes as any)
        .value as ApolloQueryResult<GetLeafletsForIntervalQuery>;

      const leafletslVal = (leafletsRes as any)
        .value as ApolloQueryResult<GetLeafletsQuery>;

      const topRetailersVal = (topRetailersRes as any)
        .value as ApolloQueryResult<GetTopRetailersQuery>;

      const topCitiesVal = (topCitiesRes as any)
        .value as ApolloQueryResult<GetCitiesTopQuery>;

      const offersRetailerVal = (offersRetailerRes as any)
        .value as ApolloQueryResult<GetOffersTopByRetailerQuery>;

      const seoInfoVal = (seoInfoRes as any)
        .value as ApolloQueryResult<GetSeoInfoQuery>;

      // used from Apollo cache
      // const homeCategoriesVal = (homeCategoriesRes as any)
      //   .value as ApolloQueryResult<GetHomeCategoriesQuery>;

      paramsForPage.props.initialApolloState = client.cache.extract();

      paramsForPage.props.leafletsForInterval =
        leafletsForIntervalVal?.data?.OfferContainers.data;

      paramsForPage.props.leaflets = leafletslVal.data.OfferContainers.data;

      paramsForPage.props.topRetailers = topRetailersVal.data.Retailers?.data;
      paramsForPage.props.topCities = topCitiesVal.data.Cities?.data;
      paramsForPage.props.offersRetailer = offersRetailerVal.data.Offers?.data;

      // seo data
      paramsForPage.props.seoInfo = seoInfoVal?.data?.SeoInfo;

      paramsForPage.props.seoTitle = t("seo.home_title");
      paramsForPage.props.seoDescription = t("seo.home_description");
      paramsForPage.props.currentBreadcrumb = [];
      // --- seo data

      // Elimino le pgHomeCategories da redux perchè in questa pagina li abbiamo già .... successivamente questo va tolto
      delete paramsForPage.props.globalThisServer.pqHomePageCategories;

      return paramsForPage;
    },
);

export default connect((state) => state)(Home);
